import React from "react"
import { Col, Container, Row } from "@bootstrap-styled/v4"
import styled from "styled-components"

import fonts from "../../fonts/fonts.css"

import BenchmarkLineChart from "../organisms/BenchmarkLineChart/BenchmarkLineChart"
import ComplianceTrackerSelect from "../molecules/ComplianceTrackerSelect"
import QuestionMarkIcon from "../atoms/QuestionMarkIcon/QuestionMarkIcon"

import vsLogo from "../../images/VS_horizontal_BLACK.png"
import blueAppendix from "../../images/blue-appendix.svg"

const ComplianceTrends = ({charts, practice, date, type, regions}) => {
  const selectItems = [
    `All ${type}s`,
    `${type === 'Canine' ? 'Puppies' : 'Kittens'} < 1 year`,
    'Adult 1-7 years',
    'Senior 8+ years'
  ];

  return (
    <ComplianceTrendsContainer>
      <Header>
        <div>{practice}</div>
        <div>{date}</div>
        <Logo src={vsLogo}/>
      </Header>
      <Legend>
        <LeftLegend>
          <Title>
            <div>Compliance Trends – {type}</div>
            <QuestionMarkIcon/>
          </Title>
          <Description>
            How has your practice’s compliance changed over the past 24 months?<br />
            How does your practice compare to other practices in your region
            {regions && regions.length > 1 ? 's' : ''} ({regions.join(', ')})?
          </Description>
        </LeftLegend>
        <RightLegend>
          <ColorLegend>
            <GreenLegend>
              <LightGreenBg>
                <GreenCircle/>
              </LightGreenBg>
              <div>
                <GreenText>Current Year</GreenText>
                <LightGreenText>
                  Optimal<br/>Benchmark Range
                  <QuestionMarkIcon
                    title="Optimal Benchmark Range:"
                    text={`The 50th-90th percentile of all VetSuccess practices in the ${regions.join(', ')} 
                    region${regions && regions.length > 1 ? 's' : ''}`}
                  />
                </LightGreenText>
              </div>
            </GreenLegend>
            <GrayLegend>
              <GrayCircle/>
              <GrayText>Previous Year</GrayText>
            </GrayLegend>
          </ColorLegend>
          <Appendix>
            <AppendixIcon src={blueAppendix}/>
            <AppendixLinks>
              <AppendixLink>
                <span>- Patient Audit Appendix</span>
              </AppendixLink>
              <AppendixLink>
                <span>- Benchmark Numbers Appendix</span>
              </AppendixLink>
            </AppendixLinks>
          </Appendix>
        </RightLegend>
      </Legend>
      <ComplianceTrackerSelect items={selectItems}/>
      <Container>
        <Row>
          {charts.map((chart, index) => {
            return (
              <BenchmarkChartContainer lg="6" key={index}>
                <BenchmarkLineChart
                  totalWidth={chart.totalWidth}
                  totalHeight={chart.totalHeight}
                  title={chart.title}
                  subtitle={chart.subtitle}
                  currentYearData={chart.currentYearData}
                  previousYearData={chart.previousYearData}
                  benchmarkData={chart.benchmarkData}
                  years={chart.years}
                />
              </BenchmarkChartContainer>
            )
          })}
        </Row>
      </Container>
    </ComplianceTrendsContainer>
  )
}

export default ComplianceTrends;

const ComplianceTrendsContainer = styled.div`
  max-width: 1170px;
  margin: auto;
  padding 0 15px;
  font-family: HelveticaNeueLTStd, sans-serif;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 15px 0;
  padding-bottom: 3px;
  border-bottom: solid 0.5pt #3C3B3B;
  font-size: 10pt;
  font-weight: 300;
`

const Logo = styled.img`
  width: 80px;
`

const Legend = styled.div`
  display: flex;
  margin: 0 15px 20px;
  padding: 20px 0;
  border-bottom: solid 6pt #B2B4B7;
`

const LeftLegend = styled.div`
  flex: 1;
  margin-right: 30px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 25pt;
  font-weight: bold;
  color: #8EC641;
`

const Description = styled.div`
  font-size: 12pt;
  font-weight: 300;
`

const RightLegend = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const ColorLegend = styled.div`
  font-size: 12pt;
  font-weight: bold;
`

const GreenLegend = styled.div`
  display: flex;
  align-items: center;
`

const LightGreenBg = styled.div`
  align-self: normal;
  background-color: #DFEDC9;
`

const GreenCircle = styled.div`
  width: 15px;
  height: 15px;
  margin: 10px;
  border-radius: 50%;
  background-color: #447B04;
`

const GreenText = styled.div`
  margin: 10px 0 5px 10px;
  color: #447B04;
`

const LightGreenText = styled.div`
  margin-left: 10px;
  color: #B0D57D;
`

const GrayLegend = styled.div`
  display: flex;
  align-items: center;
`

const GrayCircle = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 10px;
  border-radius: 50%;
  background-color: #B2B4B7;
`

const GrayText = styled.div`
  margin-left: 10px;
  color: #B2B4B7;
`

const Appendix = styled.div`
  display: inline-flex;
  align-items: start;
  font-size: 8pt;
  font-weight: bold;
  color: #5978CF;
  text-decoration: underline;
`

const AppendixIcon = styled.img`
  width: 30px;
  margin-right: 5px;
`

const AppendixLinks = styled.div`
  display: inline-block;
`

const AppendixLink = styled.div`
  padding-bottom: 5px;
  
  > span {
    cursor: pointer;
  }
`

const BenchmarkChartContainer = styled(Col)`
  text-align: center;
`
