import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComplianceTrends from "../components/templates/ComplianceTrends"

const currentYearData = [
  {month: 'APR', value: 58},
  {month: 'MAY', value: 86},
  {month: 'JUN', value: 52},
  {month: 'JUL', value: 75},
  {month: 'AUG', value: 45},
  {month: 'SEP', value: 50},
  {month: 'OCT', value: 55},
  {month: 'NOV', value: 51},
  {month: 'DEC', value: 59},
  {month: 'JAN', value: 50},
  {month: 'FEB', value: 60},
  {month: 'MAR', value: 56}
];

const previousYearData = [
  {month: 'APR', value: 53},
  {month: 'MAY', value: 56},
  {month: 'JUN', value: 50},
  {month: 'JUL', value: 48},
  {month: 'AUG', value: 54},
  {month: 'SEP', value: 55},
  {month: 'OCT', value: 50},
  {month: 'NOV', value: 48},
  {month: 'DEC', value: 42},
  {month: 'JAN', value: 45},
  {month: 'FEB', value: 55},
  {month: 'MAR', value: 61}
];

const benchmarkData = [
  {month: 'APR', min: 49, max: 66},
  {month: 'MAY', min: 49, max: 66},
  {month: 'JUN', min: 49, max: 66},
  {month: 'JUL', min: 49, max: 66},
  {month: 'AUG', min: 49, max: 66},
  {month: 'SEP', min: 49, max: 66},
  {month: 'OCT', min: 49, max: 66},
  {month: 'NOV', min: 49, max: 66},
  {month: 'DEC', min: 49, max: 66},
  {month: 'JAN', min: 49, max: 66},
  {month: 'FEB', min: 49, max: 66},
  {month: 'MAR', min: 49, max: 66}
];

const chart1 = {
  totalWidth: 555,
  totalHeight: 200,
  title: 'Wellness Bloodwork',
  subtitle: 'Includes bloodwork with at least a CBC/Chemistry panel such as Wellness, Life Stage and Preanesthetic Panels',
  currentYearData: currentYearData,
  previousYearData: previousYearData,
  benchmarkData: benchmarkData,
  years: 1
}

const chart2 = {
  totalWidth: 555,
  totalHeight: 200,
  title: 'Dental Prophylaxis',
  subtitle: '',
  currentYearData: currentYearData,
  previousYearData: previousYearData,
  benchmarkData: benchmarkData,
  years: 1
}

const chart3 = {
  totalWidth: 555,
  totalHeight: 200,
  title: 'Internal Parasite Coverage',
  subtitle: 'Includes Internal Parasiticides that cover: Heartworm; +/- Hookworms, Roundworms, Whipworms and Tapeworms',
  currentYearData: currentYearData,
  previousYearData: previousYearData,
  benchmarkData: benchmarkData,
  years: 1
}

const chart4 = {
  totalWidth: 555,
  totalHeight: 200,
  title: 'External Parasite Coverage',
  subtitle: 'Includes External Parasiticides that cover: Fleas; +/- Ticks',
  currentYearData: currentYearData,
  previousYearData: previousYearData,
  benchmarkData: benchmarkData,
  years: 1
}

const ComplianceTrendsPage = () => (

  <Layout>
    <SEO title="Compliance Trends" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <ComplianceTrends
      practice='ABC Animal Hospital'
      date='March 2020'
      type='Canine'
      regions={['TX']}
      charts={[chart1, chart2, chart3, chart4]}
    />
  </Layout>
)

export default ComplianceTrendsPage
