import React from "react"
import styled from "styled-components"

const ComplianceTrackerSelect = ({items}) => {
  return <SelectContainer>
    {items.map((item, index) => {
      return <option key={index} value={index}>{item}</option>
    })}
  </SelectContainer>;
}

export default ComplianceTrackerSelect;

const SelectContainer = styled.select`
  width: 555px;
  margin-left: 15px;
  padding: 5px;
  font-family: HelveticaNeueLTStd, sans-serif;
  font-size: 8pt;
  font-weight: bold;
  text-transform: uppercase;
`
